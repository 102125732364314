<template>
  <header class="header">
    <img class="header-logo" src="../../assets/images/logo.png" alt="" />
    <div class="header-title" v-if="!disableTitle">武汉小咪网络科技有限公司</div>
    <div class="spacer"></div>
    <ul class="header-menu" v-if="!isBY">
      <li class="header-menu-item" :class="{ active: index == 0, 'is-baidu': item.id == 2 && isBaidu, 'is-qihu': item.id == 2 && isQihu }" v-for="(item, index) in menu" :key="index" @click="onMenuClick(item)">
        {{ item.name == '首页' && disableTitle ? '' : item.name }}
      </li>
    </ul>
    <ul class="BYMenu" v-else>
      <li class="header-menu-item" :class="{ active: index == 0, 'is-baidu': item.id == 2 && isBaidu, 'is-qihu': item.id == 2 && isQihu,'is-qihu2': isBY}" v-for="(item, index) in isBYMenu" :key="index" @click="onMenuClickBY(item)">
        <i v-if="item.id == 2"></i>
        {{ item.name == '官网' && disableTitle ? '' : item.name }}
      </li>
    </ul>
  </header>
</template>

<script>
export default {
  props: {
    channel: {
      type: null,
      default: 0,
    },
    disableTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    self: null,
    menu: [
      {
        id: 1,
        name: "首页",
      },
      {
        id: 2,
        name: "PC端下载",
      },
    ],
    isBYMenu: [
      {
        id: 1,
        name: "官网",
      },
      {
        id: 2,
        name: "立即下载",
      },
    ],
  }),
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    isQihu() {
      return this.channel == 20200707006;
    },
    isBY(){
      return this.channel == 2887211655
    },
  },
  methods: {
    onMenuClick(item) {
      switch (item.name) {
        case "首页":
          _czc.push(["_trackEvent", "落地页 ", "点击", "首页按扭"]);
          window.location.href = "/";
          break;
        case "PC端下载":
          this.$emit("download");
          break;
        default:
          break;
      }
    },
    onMenuClickBY(item) {
      switch (item.name) {
        case "官网":
          _czc.push(["_trackEvent", "落地页 ", "点击", "首页按扭"]);
          window.location.href = "/";
          break;
        case "立即下载":
          this.$emit("download");
          _czc.push(["_trackEvent", "落地页 ", "点击下载", "右上角快速"]);
          break;
        default:
          break;
      }
    },
    // uet_report_conversion() {
    //   window.uetq = window.uetq || [];
    //   window.uetq.push("event", "点击按钮", { event_category: "普通下载" });
    // },
    // downloadUrl() {
    //   window.location.href =
    //     "https://big.softdl.360tpcdn.com/auto/20240909/1900005876_637a2983635010ab0e68063b5237ee38.exe";
    // },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  height: 84px;
  font-family: "Microsoft YaHei";
  padding: 0 100px;
  .header-title {
    margin: 0 22px;
    font-size: 16px;
    color: black;
    letter-spacing: 3px;
  }
}
.spacer {
  flex: 1;
}
.header-menu {
  display: flex;
  align-items: center;
  .header-menu-item {
    font-size: 18px;
    font-weight: bold;
    color: #111010;
    padding: 0 27px;
    cursor: pointer;
  }
}
.BYMenu {
  display: flex;
  align-items: center;
  .header-menu-item {
    cursor: pointer;
    &:nth-child(1) {
      font-weight: bold;
      font-size: 18px;
      color: #111010;
      margin-right: 35px;
      &:hover{
        color: #3da7fe;
      }
    }

    &:nth-child(2) {
      width: 140px;
      height: 40px;
      background: #3da7fe;
      border-radius: 8px;
      border: 2px solid #2197f9;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      i {
        width: 18px;
        height: 18px;
        background-image: url("../../assets/images/icon-download.png");
        background-size: 100% 100%;
        display: block;
        margin-right: 6px;
      }
    }
  }
}
</style>
